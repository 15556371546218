import * as React from 'react';
import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Header from '../../Common/Headers/Header';
import { withStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TextField from '@material-ui/core/TextField';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import FinishInstallDialog from '../../Common/Popups/FinishInstallDialog';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

import theme from '../../Common/Themes/login_theme';
import client from '../../Common/utils/client';
import * as routes from '../unitdetail_apiRoutes';
import { spinnerService } from '../../Common/utils/spinnerService';
import Spinner from '../../Common/Loading/Spinner';
import logo from '../../Common/images/logo.svg';
import serial from '../../Common/images/Dwelo-FCC-Label-3.png';

import '../../Common/stylesheets/global.scss';
import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';
import { SUBMIT_FROM_HUB_ASSOCIATION } from '../../Common/Constants/launchDarklyConstants';
import { RouteComponentProps } from 'react-router';
import { User } from '../../Common/Types/cloudApi';
import { handle401ErrorRedirect, is401Error } from '../../Common/utils/errorHandling';

const styles: any = (theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: '16px',
    backgroundColor: '#4ad4d4'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: '100%'
  },
  imageField: {
    marginTop: '16px',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: '100%'
  },
  incompleteSmOrGreater: {
    display: 'block',
    left: '50%',
    marginLeft: '-63.5px',
    bottom: '16px'
  },
  incompleteXsScreen: {
    bottom: '72px'
  },
  incomplete: {
    position: 'fixed',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#ffffff !important',
      opacity: 1
    }
  }
});

type Params = {
  communityId: string;
  unitId: string;
};

type State = {
  serial_number: string;
  error: string;
};

type Props = RouteComponentProps<Params> & {
  flags: LDFlagSet;
  classes: {
    incompleteSmOrGreater: string;
    incompleteXsScreen: string;
    incomplete: string;
    fab: string;
    container: string;
    textField: string;
    imageField: string;
  };
  user: User;
  width: Breakpoint;
  unit: {
    uid: string | number;
    unit: string;
    communityName: string;
  };
  // These optional props will eventually become required
  // when the SUBMIT_FROM_HUB_ASSOCIATION is no longer in use.
  workTicketAvailable?: boolean;
  incompleteReasons?: Array<string>;
  handleOpen?: any;
  handleClose?: any;
  handleInputChange?: any;
  handleSubmit?: any;
  showFinishInstallDialog?: boolean;
  installStatusRadio?: string;
  incompleteReason?: string;
  closingNotesText?: string;
  waitingSubmitResponse?: boolean;
};

class AssociateHub extends Component<Props, State> {
  classes = this.props.classes;
  state: State = {
    serial_number: '',
    error: ''
  };

  resetFabPosition() {
    let centralColumn: HTMLElement | null = document.getElementById('central-column');
    let fixedFooter: HTMLElement = Array.from(
      document.getElementsByClassName('keep-fixed') as HTMLCollectionOf<HTMLElement>
    )[0];

    if (fixedFooter && centralColumn) {
      const centralColumnWidth = parseInt(centralColumn.style.width, 10);
      // 56 is the material-ui Fab component width
      fixedFooter.style.marginLeft = centralColumnWidth - fixedFooter.offsetWidth - 16 + 'px';

      const incompleteButton = document.getElementById('incomplete-button');
      if (incompleteButton) {
        const incompleteButtonWidth = incompleteButton.offsetWidth;
        if (isWidthUp('sm', this.props.width)) {
          // for centering the button horizontally
          incompleteButton.style.marginLeft = `-${incompleteButtonWidth / 2}px`;
        } else {
          // for giving a fab-like marginLeft, so that it is right-aligned with fixedFooter
          incompleteButton.style.marginLeft = `${
            centralColumnWidth - incompleteButtonWidth - 16
          }px`;
        }
      }
    }
  }

  componentDidMount() {
    this.resetFabPosition();
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapshot: any) {
    if (
      prevProps.flags[SUBMIT_FROM_HUB_ASSOCIATION] !==
        this.props.flags[SUBMIT_FROM_HUB_ASSOCIATION] ||
      prevProps.width !== this.props.width
    ) {
      this.resetFabPosition();
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name: any = e.target.name;
    const value: any = e.target.value;
    this.setState({
      [name]: value
    } as any);
  };

  associateHub = (e: React.MouseEvent<HTMLButtonElement> | null) => {
    if (e) {
      e.preventDefault();
      this.setState({
        error: ''
      });
    }
    spinnerService.show('default');
    client
      .post(routes.ASSOCIATE_ADDRESS, {
        dwelo_hub_serial: this.state.serial_number,
        address_id: this.props.unit.uid
      })
      .then(() => {
        window.location.reload();
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401Error(error)) {
          handle401ErrorRedirect(error, this.props.history);
          return;
        }
        Sentry.captureException(error);

        spinnerService.hide('default');
        // Backend is not consistent with message key
        this.setState({
          error:
            error.response?.data['custom'] ||
            error.response?.data['message'] ||
            `An error occurred (${error}). Please try again.`
        });
      });
  };

  returnToUnits = () => {
    const communityId = this.props.match.params.communityId;
    this.props.history.push('/community/' + communityId);
  };

  getButtons() {
    if (this.props.flags[SUBMIT_FROM_HUB_ASSOCIATION]) {
      let incompleteButton;
      if (this.props.workTicketAvailable) {
        let incompleteClass = isWidthUp('sm', this.props.width)
          ? `${this.classes.incompleteSmOrGreater}`
          : `${this.classes.incompleteXsScreen} keep-fixed`;
        incompleteClass += ` ${this.classes.incomplete}`;
        incompleteButton = (
          <Button
            id={'incomplete-button'}
            color={'primary'}
            variant={'outlined'}
            className={incompleteClass}
            size={'large'}
            onClick={this.props.handleOpen}
          >
            Incomplete
          </Button>
        );
      }

      return (
        <div>
          <Button
            id={'continue-button'}
            color={'primary'}
            variant={'contained'}
            className={this.classes.fab + ' keep-fixed'}
            size={'large'}
            onClick={this.associateHub}
            endIcon={<ArrowForwardIcon />}
          >
            Continue
          </Button>
          {incompleteButton}
        </div>
      );
    } else {
      return (
        <Fab
          color="primary"
          aria-label="Next"
          className={this.classes.fab + ' keep-fixed'}
          onClick={this.associateHub}
        >
          <ArrowForwardIcon />
        </Fab>
      );
    }
  }

  getFinishInstallDialog = () => {
    if (this.props.workTicketAvailable && this.props.flags[SUBMIT_FROM_HUB_ASSOCIATION]) {
      return (
        <FinishInstallDialog
          open={this.props.showFinishInstallDialog}
          incompleteOnly={true}
          installStatusRadio={this.props.installStatusRadio}
          incompleteReason={this.props.incompleteReason}
          incompleteReasons={this.props.incompleteReasons}
          closingNotesText={this.props.closingNotesText}
          waitingSubmitResponse={this.props.waitingSubmitResponse}
          handleClose={this.props.handleClose}
          handleInputChange={this.props.handleInputChange}
          handleSubmit={this.props.handleSubmit}
          handleOpen={this.props.handleOpen}
        />
      );
    } else {
      return null;
    }
  };

  render() {
    const buttons = this.getButtons();
    const finishInstallDialog = this.getFinishInstallDialog();

    return (
      <div className="full-height">
        <div className="addunit-container">
          <Header
            headerText="Assign Hub"
            back={true}
            return={this.returnToUnits}
            arrow={true}
            user={this.props.user}
            history={this.props.history}
            subheaderText={this.props.unit.communityName}
            subheaderText2={this.props.unit.unit}
          />
          <MuiThemeProvider theme={theme}>
            <form className={this.classes.container}>
              <TextField
                label="Hub Serial Number"
                id="serial_number"
                name="serial_number"
                className={this.classes.textField}
                onChange={this.handleChange}
                margin="normal"
              />
            </form>
          </MuiThemeProvider>
          <Typography component="p" color="error" className={this.classes.textField}>
            {this.state.error}
          </Typography>
          <img src={serial} alt="hub_serial" className={this.classes.imageField} />
        </div>
        <MuiThemeProvider theme={theme}>
          {buttons}
          {finishInstallDialog}
        </MuiThemeProvider>
        <Spinner name="default" loadingImage={logo} />
      </div>
    );
  }
}

export default withLDConsumer()(
  withWidth({ initialWidth: 'xs' })(withStyles(styles)(AssociateHub))
);
